export default {

  init() {
    this.handlePlay();
  },

  //

  handlePlay() {
    document.querySelectorAll('.module--text-image .video-wrap .btn').forEach(button => {
      button.addEventListener('click', event => {
        const videoWrap = event.target.closest('.video-wrap'),
              embedCode = atob(videoWrap.dataset.embed),
              iframe    = document.createElement('iframe'),
              domParser = new DOMParser();

        let parsedHtml, url;
        parsedHtml = domParser.parseFromString(embedCode, 'text/html');
        url = parsedHtml.querySelector('iframe').getAttribute('src');

        iframe.setAttribute('src', url + '?autoplay=1');
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', '');
        videoWrap.insertBefore(iframe, null);
      });
    });
  }

}