export default {

  init() {
    this.maybeApplyDarkFavicons();
  },

  //

  maybeApplyDarkFavicons() {
    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      ['png', 'ico'].forEach(extension => {
        document
          .querySelectorAll(`[rel*="icon"][href$=".${extension}"]`)
            .forEach(link => link.attributes.href.value = link.attributes.href.value.replace(`.${extension}`, `-neg.${extension}`))
      });
    }
  }

}