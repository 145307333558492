import watchForHover from 'lib/input-detector';

import config   from 'config';
import debug    from 'debug';
import form     from 'form';
import head     from 'head';
import header   from 'header';
import layout   from 'layout';
import modules  from 'modules';

window.addEventListener('DOMContentLoaded', () => {
  watchForHover();

  form.init();
  head.init();
  header.init();
  layout.init();
  modules.init();

  if(config.debug) {
    debug.init();
  }
});

