import handlers from 'header-handlers';

export default {
  init() {
    handlers.attach(this);
  },

  //

  clearMenus() {
    const header = document.querySelector('#header'),
          containers = document.querySelectorAll('#header ul .container'),
          dropdownMenuEntries = document.querySelectorAll('.nav-main > div > ul > li > span.title');

    containers.forEach(c => c.classList.remove('visible'));
    header.classList.remove('with-overlay');
    dropdownMenuEntries.forEach(entry => entry.classList.remove('hover'));
  }
}