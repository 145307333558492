export default {

  init() {
    this.handleQuestionClick();
  },

  //

  handleQuestionClick() {
    document.querySelectorAll('.module--faq li header').forEach(el => el.addEventListener('click', event => {
      const target      = event.target,
            li          = target.closest('li'),
            isExpanded  = li.classList.contains('expanded');

      document.querySelectorAll('.module--faq li').forEach(el => el.classList.remove('expanded'));
      if(!isExpanded) {
        target.closest('li').classList.add('expanded');
      }
    }));
  }

}