export default {

  init() {
    this.handleTabClick();
    this.handleProductClick();
  },

  //

  handleTabClick() {
    document.querySelectorAll('.module--pricing .tabs li span').forEach(el => el.addEventListener('click', event => {
      const target        = event.target,
      li            = target.closest('li'),
            subscription  = li.dataset.subscription,
            ul            = li.parentElement,
            module        = ul.closest('.module');

      Array.from(ul.children).forEach(li => li.classList.remove('active'));
      li.classList.add('active');
      module.classList.remove('monthly', 'yearly');
      module.classList.add(subscription);
    }));
  },

  handleProductClick() {
    document.querySelectorAll('.module--pricing [data-product]').forEach(el => el.addEventListener('click', event => {
      const btn = event.target.closest('[data-product]'),
            productId = parseInt(btn.dataset.product),
            promise = fetch(`/wp-admin/admin-ajax.php?action=product&id=${productId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });
      promise
        .then(() => {
          document.location.href = btn.closest('.module--pricing').dataset.checkout;
        });
    }));
  }

}