import util from '../util';

export default {

  init() {
    this.handleTabClick();
  },

  //

  handleTabClick() {
    document.querySelectorAll('.module--tabs-image ul.tabs li span').forEach(tab => {
      tab.addEventListener('click', event => {
        const li      = event.target.closest('li'),
              module  = li.closest('.module'),
              index   = util.nodeIndex(li),
              allDivs = module.querySelectorAll('.frames > div');
        
        Array.from(li.parentElement.children).forEach(li => li.classList.remove('active'));
        li.classList.add('active');

        allDivs.forEach(div => div.scrollTo(0, 0));

        module.dataset.tab = index;
      });
    });
  }

}