import categories   from 'categories';
import faq          from 'faq';
import pricing      from 'pricing';
import tabsImage    from 'tabs-image';
import testimonials from 'testimonials';
import textImage    from 'text-image';
import video        from 'video';

export default {

  init() {
    categories.init();
    faq.init();
    pricing.init();
    tabsImage.init();
    testimonials.init();
    textImage.init();
    video.init();
  }

}