export default {

  init() {
    this.handlePagination();
  },

  //

  handlePagination() {
    document.querySelectorAll('.module--testimonials span.prev, .module--testimonials span.next').forEach(span => {
      span.addEventListener('click', event => {
        const step    = event.target.classList.contains('prev') ? -1 : 1,
              module  = event.target.closest('.module'),
              ul      = module.querySelector('.content > ul'),
              liCount = ul.childElementCount,
              index   = parseInt(module.dataset.frame),
              target  = (index + step + liCount) % liCount;
        module.dataset.frame = target;
      });
    });
  }

}