export default {

  headerRef: null,

  attach(headerRef) {
    this.headerRef = headerRef;
    this.navMainHover();
    this.navToggle();
    // this.navTopClick();
    this.scroll();
  },

  navToggle() {
    document.querySelectorAll('.nav-toggle').forEach(node => node.addEventListener('click', () => {
      const header = document.querySelector('#header');

      if(
        window.innerWidth >= 768 &&
        header.classList.contains('with-overlay')
      ) {
        this.headerRef.clearMenus();
        setTimeout(() => {
          document.documentElement.classList.toggle('navExpanded');
        }, 500);
      } else {
        document.documentElement.classList.toggle('navExpanded');
      }
    }));
    document.querySelectorAll('.nav-back').forEach(node => node.addEventListener('click', this.headerRef.clearMenus));
  },

  navTopClick() {
    document.querySelectorAll('.nav-top a').forEach(a => a.addEventListener('click', event => {
      const li  = a.parentElement,
            ul  = li.parentElement,
            lis = [...ul.children];

      lis.forEach(li => li.classList.remove('active'));
      li.classList.add('active');
      event.preventDefault();
    }));
  },

  navMainHover() {
    const header = document.querySelector('#header'),
          navMainUl = document.querySelector('.nav-main > div > ul'),
          menuEntries = document.querySelectorAll('.nav-main > div > ul > li > .title');

    if(!navMainUl) {
      return;
    }
    
    menuEntries.forEach(entry => {
      const container = entry.nextElementSibling;
      entry.addEventListener('mouseenter', event => {
        if(event.target.tagName == 'SPAN') {
          // Cancel timeout
          clearTimeout(this.entryMouseLeaveTimeout);

          this.entryMouseEnterTimeout = setTimeout(() => {
            // Show menu
            const containers = document.querySelectorAll('#header ul .container');
            containers.forEach(c => c.classList.remove('visible'));
            event.target.nextElementSibling.classList.add('visible');
            event.target.nextElementSibling.scroll(0, 0);
            header.classList.add('with-overlay');

            // Add hover class
            menuEntries.forEach(entry => entry.classList.remove('hover'));
            entry.classList.add('hover');
          }, header.classList.contains('with-overlay') || window.innerWidth < 1024 ? 0 : 300);
        } else {
          // Add hover class
          menuEntries.forEach(entry => entry.classList.remove('hover'));
          entry.classList.add('hover');
        }

      });
      entry.addEventListener('mouseleave', event => {
        // Clear hover if regular link
        if(event.target.tagName == 'A') {
          // event.target.classList.remove('hover');
        } else {
          // Set mouseleave timeout
          this.entryMouseLeaveTimeout = setTimeout(this.headerRef.clearMenus, 300);
        }
      });
      if(container) {
        container.addEventListener('mouseenter', () => clearTimeout(this.entryMouseLeaveTimeout));
        container.addEventListener('mouseleave', () => this.entryMouseLeaveTimeout = setTimeout(this.headerRef.clearMenus, 300));
      }
    });

    navMainUl.addEventListener('mouseenter', () => {
      clearTimeout(this.menuMouseLeaveTimeout);
      this.menuMouseEnterTimeout = setTimeout(() => {
        navMainUl.classList.add('hover');
      }, 0);
    });
    navMainUl.addEventListener('mouseleave', () => {
      clearTimeout(this.menuMouseEnterTimeout);
      clearTimeout(this.entryMouseEnterTimeout);
      this.menuMouseLeaveTimeout = setTimeout(() => {
        const regularMenuEntries = document.querySelectorAll('.nav-main > div > ul > li > a.title');
        regularMenuEntries.forEach(entry => entry.classList.remove('hover'));
        navMainUl.classList.remove('hover');
      }, 300);
    });
  },

  scroll() {
    if(document.querySelector('nav.nav-top')) {
      window.addEventListener('scroll', () => {
        const currentScrollY = window.scrollY,
              lastScrollY    = this.headerRef.lastScrollY || 0;
        document.querySelector('#header').classList.toggle('scrolled-down', currentScrollY > lastScrollY && currentScrollY > 1);
        this.headerRef.lastScrollY = currentScrollY;
      });
    }
  }

}