function watchForHover() {
  let hasHoverClass = false,
      lastTouchTime = 0;

  function enableHover() {
    // Filter emulated events coming from touch events
    if(
      new Date() - lastTouchTime < 500 ||
      hasHoverClass
    ) {
      return;
    }

    document.documentElement.classList.add('hasHover');
    hasHoverClass = true;
  }

  function disableHover() {
    if(!hasHoverClass) {
      return;
    }

    document.documentElement.classList.remove('hasHover');
    hasHoverClass = false;
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date();
  }

  document.addEventListener('touchstart', updateLastTouchTime, true);
  document.addEventListener('touchstart', disableHover, true);
  document.addEventListener('mousemove', enableHover, true);

  enableHover();
}

export default watchForHover;