export default {

  init() {
    this.handleToggle();
  },

  //

  handleToggle() {
    document.querySelectorAll('.module--categories .more').forEach(more => {
      more.addEventListener('click', event => {
        const target      = event.target,
              module      = target.closest('.module');

        module.classList.toggle('expanded');
      })
    });
  }

}