export default {

  nodeIndex(node) {
    let index = 0;
    while(node.previousElementSibling) {
      node = node.previousElementSibling;
      index ++;
    }

    return index;
  }

}