export default {

  init() {
    this.dropdown();
    this.keyboardFocus();
    this.animation();
    this.handleFocus();
    this.handleSubmit();
  },

  //

  dropdown() {
    const dropdowns = document.querySelectorAll('.field--dropdown');
    dropdowns.forEach(dropdown =>  {
      const placeholder = dropdown.querySelector('option.placeholder').innerText,
            span        = dropdown.querySelector('span'),
            select      = dropdown.querySelector('select');
      span.innerText = placeholder;
      dropdown.classList.add('placeholder');
      select.addEventListener('change', () => {
        const option = select.querySelector(':checked');
        span.innerText = option.innerText;
        dropdown.classList.remove('placeholder');
      });
      select.dispatchEvent(new CustomEvent('change'));
    });
  },

  keyboardFocus() {
    document.addEventListener('keyup', event => {
      if(event.key == 'Tab') {
        document.documentElement.classList.add('tabFocus');
      }
    });
    document.addEventListener('click', () => {
      document.documentElement.classList.remove('tabFocus');
    })
  },

  handleFocus() {
    document.querySelectorAll('.form input, .form textarea, .form select').forEach(
      field => field.addEventListener('focus', event => {
        const field = event.target.closest('.field');
        field && field.classList.remove('has-error');
      }
    ));
  },

  handleSubmit() {
    document.querySelectorAll('.form-wrap form').forEach(form => form.addEventListener('submit', event => {
      const wrap    = form.closest('.form-wrap'),
            module  = form.closest('.module'),
            action  = form.getAttribute('action'),
            isMock  = /assets\/json/.test(action),
            options = {};
  
      event.preventDefault();

      wrap.classList.add('busy');
  
      if(isMock) {
        options.method = 'get';
      } else {
        options.method = 'post';
        options.body = new FormData(form);
      }
  
      // Reset validation
      form.querySelectorAll('.has-error').forEach(field => field.classList.remove('has-error'));
  
      setTimeout(() => {
        fetch(action, options)
          .then(response => response.json())
          .then(response => {
            wrap.classList.remove('busy');
            if(response.status == 'ok') {
              form.reset();
              form.querySelectorAll('select').forEach(select => select.dispatchEvent(new CustomEvent('change')));
              module.classList.add('success');
            } else {
              response.fields.forEach(name => {
                const input = form.querySelector(`[name="${name}"]`);
                input && input.closest('.field').classList.add('has-error');
              });
              if(isMock && response.action) {
                form.setAttribute('action', response.action);
              }
            }
          })
          .catch(() => {
            wrap.classList.remove('busy');
          })
      }, isMock ? 1500 : 0);
    }));
  },

  animation() {
    document.querySelectorAll('.form .field').forEach(
      field => field.addEventListener('animationend', event => {
        if(event.animationName == 'shake') {
          event.target.classList.remove('shake');
        }
      }
    ));
  }

}