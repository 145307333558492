export default {

  init() {
    this.attachResizeHandler();
  },

  //

  attachResizeHandler() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('orientationchange', this.onResize);
    window.dispatchEvent(new Event('resize'));
  },

  onResize() {
    const halfGutterWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--half-gutter'));
      
    document.querySelectorAll('.cross-grid').forEach(element => {
      let   refFrom, refTo, width;
      
      refFrom = document.querySelector('#footer .ref .col-1:nth-child(8)');
      refTo   = document.querySelector('#footer .ref .container--large');
      width   = refTo.getBoundingClientRect().right - refFrom.getBoundingClientRect().left;
      width   = width - halfGutterWidth;
      element.style.width = `${width}px`;
    });

    const containerLarge = document.querySelector('#footer .ref .container--large'),
          containerRegular = containerLarge.querySelector('.container'),
          largeWidth = containerLarge.offsetWidth,
          regularWidth = containerRegular.offsetWidth - 2 * halfGutterWidth,
          diff = (largeWidth - regularWidth) / 2;
    document.documentElement.style.setProperty('--grid-diff', `${diff}px`);
  }

}