export default {

  init() {
    document.addEventListener('keypress', event => {
      if(event.code == 'KeyG' && event.ctrlKey) {
        const gridIsVisible = !document.querySelector('#debug-grid').classList.toggle('hidden');
        localStorage.setItem('gridIsVisible', gridIsVisible);
      }
    });
    window.addEventListener('DOMContentLoaded', () => {
      setInterval(() => {
        const gridIsVisible = JSON.parse(localStorage.getItem('gridIsVisible'));
        document.querySelector('#debug-grid').classList.toggle('hidden', !gridIsVisible);
      }, 500);
    });
    document.querySelectorAll('a[href="#"]').forEach(
      a => a.addEventListener('click', event => event.preventDefault())
    );  
  }
  
}